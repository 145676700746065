import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Delete, Edit, QueryStats, Visibility } from '@mui/icons-material';
import { useForm } from 'react-hook-form';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toastWarning } from 'utils/toast';
import { dropFretePorConta, dropBoolean } from 'utils/drops';
import {
  checkInfosProduto,
  findOnArray,
  summarizer,
  getDatePagto,
} from 'utils/functions';
import { DocumentosContext } from 'contexts/DocumentosContext';
import { DropsContext } from 'contexts/DropsContext';
import { AuthContext } from 'contexts/AuthContext';
import { useDialog, useModal } from 'components/Modals';
import HistoricoCompraModal from 'components/Modals/HistoricoCompraModal';
import CatalogoModal from 'components/Modals/CatalogoModal';
import TableContainer from 'components/TableContainer';
import VirtualDrop from 'components/VirtualDrop';
import ButtonMenu from 'components/ButtonMenu';
import InputMask from 'components/InputMask';
import Container from 'components/Container';
import Dropdown from 'components/Dropdown';
import Checkbox from 'components/Checkbox';
import Button from 'components/Button';
import Loader from 'components/Loader';
import Header from 'components/Header';
import Input from 'components/Input';
import Card from 'components/Card';
import Blank from 'assets/blank.png';
import api from 'services/api';
import ItemModal from './Modals/ItemModal';
import PagamentoModal from './Modals/PagamentoModal';
import styles from './styles';
import moment from 'moment';

const Gerar = () => {
  const especie_id = 4;
  const { documento_id, selected_itens } = useParams();
  const { state } = useLocation();
  const { user } = useContext(AuthContext);
  const { drops } = useContext(DropsContext);
  const { postDocumento, postLoading, getDocumento, getLoading, documento } =
    useContext(DocumentosContext);
  const defaultValues = {
    natureza_operacao_id: 1,
    documento: '',
    dtemissao: moment().format('YYYY-MM-DD HH:mm:ss'),
    filial_id: user?.filial_id,
    cadastro_id: null,
    celocal_id: null,
    moeda_id: null,
    cotacao: '',
    contato: '',
    inspecao: '',
    observacao: '',
    dadosadc: '',
    //ITENS
    produto_id: null,
    descricao: '',
    quantidade: '',
    unidade_medida_id: null,
    referencia: '',
    desconto1_pc: '',
    desconto_vlr: '',
    preco: '',
    przent: null,
    planoconta_id: null,
    ccusto_id: null,
    aplicacao_id: null,
    frota_id: null,
    infadc: '',
    itens: [],
    //PAGAMENTO
    forma_pagto_id: null,
    condicao_pagamento_id: null,
    fixar: false,
    pagamento: [],
    //ENVIO/TRANSPORTE
    fretevlr: '',
    fretepc: null,
    entidade_id: null,
    entrega: '',
  };
  const navigate = useNavigate();
  const [additionals, setAdditionals] = useState(null);
  const [loading, setLoading] = useState(false);
  const { closeModal } = useModal();
  const { openDialog, closeDialog } = useDialog();
  const { control, handleSubmit, resetField, watch, setValue } = useForm({
    defaultValues,
  });

  const renderValues = () => {
    const filtered = JSON.parse(selected_itens || '[]');
    const transporte = documento?.DocumentoTransportes?.find(
      (f) => f?.tipotransp === 'DESPACHO'
    );
    const itens = documento?.DocumentoItems?.reverse()
      ?.filter((f) => {
        if (
          Array.isArray(filtered) &&
          Boolean(filtered?.length) &&
          !filtered?.some((s) => s === f?.id)
        ) {
          return false;
        } else {
          return true;
        }
      })
      ?.map((item) => {
        const Estruturas = item?.DocumentoItemEstruturas?.map((e) => {
          delete e?.Produto;
          delete e?.documento_item_id;
          delete e?.id;
          return e;
        });
        const Processos = item?.DocumentoItemProcessos?.map((p) => {
          delete p?.DocumentoItemProcessoAponts;
          delete p?.documento_item_id;
          delete p?.id;
          return p;
        });
        const Aprovacoes = item?.DocumentoItemAprovacaos?.map((a) => {
          delete a?.documento_item_id;
          delete a?.id;
          return a;
        });
        if (Boolean(Estruturas?.length)) item['Estruturas'] = Estruturas;
        if (Boolean(Processos?.length)) item['Processos'] = Processos;
        if (Boolean(Aprovacoes?.length)) item['Aprovacoes'] = Aprovacoes;
        const gerados = item?.Destinos?.filter(
          (d) =>
            (d?.especie_natureza === 'COMPRA' ||
              d?.especie_natureza === 'FATURA') &&
            d?.especie_grupo === 'ENTRADA'
        );
        const quantidade = item?.quantidade - summarizer(gerados, 'quantidade');
        let preco = item?.preco_compra || item?.preco;
        item['quantidade_max'] = quantidade;
        item['quantidade'] = quantidade;
        item['preco_max'] = preco;
        item['preco'] = preco;
        item['Origens'] = [{ documento_item_org_id: item?.id }];
        item['subtotal'] =
          (item?.quantidade || 0) * (preco || 0) + (item?.desconto_vlr || 0);
        delete item?.Cemovimentos;
        delete item?.Cfo;
        delete item?.Destinos;
        delete item?.DocumentoItemAprovacaos;
        delete item?.DocumentoItemEstruturas;
        delete item?.DocumentoItemImpostos;
        delete item?.DocumentoItemMc;
        delete item?.DocumentoItemOrigems;
        delete item?.DocumentoItemProcessos;
        delete item?.DocumentoItemQualidades;
        delete item?.DocumentoItemVariacaos;
        delete item?.Kit;
        delete item?.Produto;
        delete item?.UnidadeMedida;
        delete item?.Vinculados;
        delete item?.Vinculos;
        delete item?.cfo_id;
        delete item?.documento_id;
        delete item?.id;
        return item;
      })
      ?.filter((f) => Boolean(f?.quantidade));
    const forma_pagto_id = documento?.DocumentoFinanceiros?.find((f) =>
      Boolean(f?.forma_pagto_id)
    )?.forma_pagto_id;
    const pagamento = documento?.DocumentoFinanceiros?.map((fin) => ({
      parcela: fin?.parcela,
      dtvenc: fin?.dtvenc,
      valor: fin?.valor,
      forma_pagto_id: fin?.forma_pagto_id,
    }));
    setValue('filial_id', documento?.filial_id || null);
    setValue('cadastro_id', documento?.cadastro_id || null);
    setValue('celocal_id', documento?.celocal_id || null);
    setValue('moeda_id', documento?.DocumentoMoeda?.moeda_id || null);
    setValue('cotacao', documento?.DocumentoMoeda?.cotacao || '');
    setValue('contato', documento?.Copedido?.contato || '');
    setValue('inspecao', documento?.Copedido?.inspecao || '');
    setValue('observacao', documento?.observacao || '');
    setValue('dadosadc', documento?.dadosadc || '');
    setValue('pagamento', pagamento);
    setValue('itens', itens);
    setValue('forma_pagto_id', forma_pagto_id);
    setValue('fretevlr', documento?.Totais?.frete || '');
    setValue('fretepc', transporte?.fretepc || null);
    setValue('entidade_id', transporte?.entidade_id || null);
    setValue('entrega', documento?.Copedido?.entrega || '');
  };

  useEffect(() => {
    if (Boolean(documento_id)) {
      getDocumento(documento_id);
    } else if (Boolean(state?.itens?.length)) {
      setValue('itens', state?.itens?.reverse());
    }
  }, []);

  useEffect(() => {
    if (Boolean(documento_id) && Boolean(documento)) {
      renderValues();
    }
  }, [documento]);

  useEffect(() => {
    const desconto_vlr =
      watch('desconto1_pc') * watch('preco') * watch('quantidade');
    setValue(
      'desconto_vlr',
      desconto_vlr !== 0 ? desconto_vlr * -0.01 : desconto_vlr
    );
  }, [watch('desconto1_pc'), watch('preco'), watch('quantidade')]);

  useEffect(() => {
    handleSubmit(onRecalc)();
  }, [watch('itens')]);

  const subtotal =
    (watch('quantidade') || 0) * (watch('preco') || 0) +
    (watch('desconto_vlr') || 0);

  const getAdditionals = async (produto_id) => {
    setLoading(true);
    const produto = await checkInfosProduto({
      produto_id,
      disponivel: 'SIM',
      filial_id: watch('filial_id'),
      cadastro_id: watch('cadastro_id'),
      condicao_pagamento_id: watch('condicao_pagamento_id'),
    });
    setAdditionals(produto);
    setValue('descricao', produto?.descricao);
    setValue('quantidade', produto?.quantidade || 1);
    setValue('unidade_medida_id', produto?.unidade_medida_id);
    setValue('referencia', produto?.referencia);
    setValue('preco', produto?.preco_compra);
    setLoading(false);
  };

  const onAddItem = (values) => {
    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue('itens', [
      {
        subtotal,
        produto_id: values?.produto_id,
        descricao: values?.descricao,
        quantidade: values?.quantidade,
        unidade_medida_id: values?.unidade_medida_id,
        referencia: values?.referencia,
        desconto1_pc: values?.desconto1_pc,
        desconto_vlr: values?.desconto_vlr,
        preco: values?.preco,
        przent: values?.przent,
        planoconta_id: values?.planoconta_id,
        ccusto_id: values?.ccusto_id,
        aplicacao_id: values?.aplicacao_id,
        frota_id: values?.frota_id,
        infadc: values?.infadc,
      },
      ...values?.itens,
    ]);
    setAdditionals(null);
    resetField('produto_id');
    resetField('descricao');
    resetField('quantidade');
    resetField('unidade_medida_id');
    resetField('referencia');
    resetField('desconto1_pc');
    resetField('desconto_vlr');
    resetField('preco');
    resetField('przent');
    resetField('planoconta_id');
    resetField('ccusto_id');
    resetField('aplicacao_id');
    resetField('frota_id');
    resetField('infadc');
  };

  const onAddItemModal = (v) => {
    const data = v?.map((i) => ({
      produto_id: i?.id,
      descricao: i?.descricao,
      quantidade: i?.quantidade || 1,
      unidade_medida_id: i?.unidade_medida_id,
      referencia: i?.referencia,
      preco: i?.custo || 0,
      subtotal: (i?.custo || 0) * (i?.quantidade || 1),
    }));
    setValue('itens', [...data, ...watch('itens')]);
    handleSubmit(onRecalc)();
    closeModal();
  };

  const onEditItem = ({ values, index }) => {
    const itens = watch('itens');
    const item = itens[index];
    if (values?.quantidade > item?.quantidade_max) {
      return toastWarning(
        `Quantidade limite excedida. (Limite: ${item?.quantidade_max})`
      );
    }
    if (Boolean(item?.preco_max) && values?.preco > item?.preco_max) {
      return toastWarning(
        `Preço limite excedido. (Limite: ${item?.preco_max})`
      );
    }

    const subtotal =
      (values?.quantidade || 0) * (values?.preco || 0) +
      (values?.desconto_vlr || 0);
    setValue(
      'itens',
      itens?.map((itm, i) =>
        index === i ? { ...itm, ...values, subtotal } : itm
      )
    );
    closeDialog();
  };

  const onDeleteItem = (index) => {
    setValue(
      'itens',
      watch('itens')?.filter((_, i) => i !== index)
    );
  };

  const onChangePagto = (values) => {
    const forma_pagto_id = values?.forma_pagto_id;
    const condicao_pagamento_id = values?.condicao_pagamento_id;
    const fixar = values?.fixar;
    if (Boolean(forma_pagto_id) && Boolean(condicao_pagamento_id)) {
      const cond_pagto = findOnArray(
        condicao_pagamento_id,
        drops?.CondicaoPagamento
      );
      const valor_total = summarizer(values?.itens, 'subtotal');

      if (cond_pagto && valor_total > 0) {
        const pagamento = [];
        let valor_lancado = 0;
        for (let parcela = 1; parcela <= cond_pagto?.nvezes; parcela++) {
          const valor = parseFloat(
            (valor_total / cond_pagto?.nvezes).toFixed(2)
          );
          pagamento.push({
            parcela,
            dtvenc: getDatePagto({
              ...cond_pagto,
              parcela,
              fixar,
              start_date: values?.dtemissao,
            }),
            valor:
              parcela === cond_pagto?.nvezes
                ? valor_total - valor_lancado
                : valor,
            forma_pagto_id,
          });
          valor_lancado += valor;
        }
        setValue('pagamento', pagamento);
      } else {
        setValue('pagamento', []);
      }
    }
  };

  const onEditPagto = ({ values, index }) => {
    const pagamento = watch('pagamento');
    setValue(
      'pagamento',
      pagamento?.map((p, i) =>
        index === i
          ? { ...p, ...values, parcela: i + 1 }
          : { ...p, parcela: i + 1 }
      )
    );
    closeDialog();
  };

  const onDeletePagto = (index) => {
    setValue(
      'pagamento',
      watch('pagamento')?.filter((_, i) => i !== index)
    );
  };

  const onRecalc = (values) => {
    const valor_total = summarizer(values?.itens, 'subtotal');
    const parcelas = values?.pagamento?.length;
    if (Boolean(parcelas) && valor_total > 0) {
      let valor_lancado = 0;
      const pagamento = values?.pagamento.map((p, i) => {
        let valor =
          parcelas === i + 1
            ? valor_total - valor_lancado
            : parseFloat((valor_total / parcelas).toFixed(2));
        valor_lancado += valor;
        return { ...p, valor };
      });
      setValue('pagamento', pagamento);
    } else {
      setValue('pagamento', []);
    }
  };

  const onChangeEntidade = async (v) => {
    if (v) {
      const params = {
        entidade_id: v?.value,
        tipo: 'FORNECEDOR',
      };
      const { data } = await api.get(`/Cadastros/Entidade/Complemento`, {
        params,
      });
      if (data?.condicao_pagamento_id) {
        setValue('condicao_pagamento_id', data?.condicao_pagamento_id);
      }
      if (data?.forma_pagto_id) {
        setValue('forma_pagto_id', data?.forma_pagto_id);
      }
    }
  };

  const onSubmit = (values) => {
    const Transportes = [];
    const Origens = state?.Origens || [];
    const hasTransp = documento?.DocumentoTransportes?.some(
      (s) => s?.tipotransp === 'DESPACHO'
    );
    if (Boolean(documento_id)) {
      Origens?.push({ documento_id });
    }
    if (Boolean(documento_id) && hasTransp) {
      documento?.DocumentoTransportes?.map((t) => {
        if (t?.tipotransp === 'DESPACHO') {
          delete t?.id;
          delete t?.documento_id;
          Transportes?.push({
            ...t,
            entidade_id: values?.entidade_id,
            fretepc: values?.fretepc,
          });
        } else {
          Transportes?.push(t);
        }
      });
    } else {
      Transportes?.push({
        tipotransp: 'DESPACHO',
        entidade_id: values?.entidade_id,
        fretepc: values?.fretepc,
      });
    }
    let data = {
      Documento: {
        especie_id,
        natureza_operacao_id: values?.natureza_operacao_id,
        users_id: user?.id,
        filial_id: values?.filial_id,
        cadastro_id: values?.cadastro_id,
        celocal_id: values?.celocal_id,
        dtemissao: values?.dtemissao,
        documento: values?.documento,
        observacao: values?.observacao,
        dadosadc: values?.dadosadc,
      },
      Entrada: {
        dtentrada: values?.dtemissao,
      },
      PedidoCompra: {
        contato: values?.contato,
        entrega: values?.entrega,
        inspecao: values?.inspecao,
      },
      Moeda: {
        moeda_id: values?.moeda_id,
        cotacao: values?.cotacao,
      },
      Itens: values?.itens?.map((i, index) => ({
        ...i,
        sequencia: index + 1,
      })),
      Financeiros: values?.pagamento,
      Transportes,
      Origens,
    };
    if (Boolean(values?.fretevlr)) {
      data = { ...data, Totais: { frete: values?.fretevlr } };
    }
    if (Boolean(documento_id)) {
      data = {
        ...data,
        Entrada: {
          ...data?.Entrada,
          serie: documento?.DocumentoEntrada?.serie,
          ped_fornec: documento?.DocumentoEntrada?.ped_fornec,
        },
      };
      if (
        Boolean(documento?.DocumentoServico) &&
        documento?.NaturezaOperacao?.natureza === 'SERVICOS'
      ) {
        data = {
          ...data,
          Servico: {
            garantia: documento?.DocumentoServico?.garantia,
            assunto: documento?.DocumentoServico?.assunto,
            defeito: documento?.DocumentoServico?.defeito,
            solicitacao: documento?.DocumentoServico?.solicitacao,
            mail_contato: documento?.DocumentoServico?.mail_contato,
          },
        };
      }
    }

    postDocumento({ data, cb: () => navigate(-1) });
  };

  const options = [
    {
      name: 'Histórico de Compras do Fornecedor',
      icon: 'work_history',
      action: () => {
        if (Boolean(watch('cadastro_id'))) {
          openDialog(
            <HistoricoCompraModal
              cadastro_id={watch('cadastro_id')}
              onSubmit={(v) => setValue('itens', [...v, ...watch('itens')])}
            />,
            'Histórico de Compras do Fornecedor'
          );
        } else {
          toastWarning('Selecione um Fornecedor');
        }
      },
    },
    {
      name: 'Histórico de Compras do Produto',
      icon: 'work_history',
      action: () => {
        if (Boolean(watch('produto_id'))) {
          openDialog(
            <HistoricoCompraModal
              produto_id={watch('produto_id')}
              onSubmit={(v) => setValue('itens', [...v, ...watch('itens')])}
            />,
            'Histórico de Compras do Produto'
          );
        } else {
          toastWarning('Selecione um Produto');
        }
      },
    },
    {
      name: 'Catálogo de Produtos',
      icon: 'balance',
      action: () =>
        openDialog(
          <CatalogoModal onSubmit={onAddItemModal} />,
          'Catálogo de Produtos'
        ),
    },
  ];

  if (getLoading) {
    return <Loader />;
  }

  return (
    <Container>
      <Header titulo="Gerar Pedido" />
      <Card title="Informações Gerais">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            <Dropdown
              name="natureza_operacao_id"
              control={control}
              label="Natureza da Operação"
              options={drops?.NaturezaOperacao?.filter(
                (f) => f?.selecao === 'SIM'
              )}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Input
              name="documento"
              control={control}
              label="Nº do Documento"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <InputMask
              name="dtemissao"
              control={control}
              label="Data de Emissão"
              type="datetime"
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="filial_id"
              control={control}
              label="Filial"
              options={drops?.Filial}
            />
          </Grid>
          <Grid item xs={10}>
            <VirtualDrop
              name="cadastro_id"
              control={control}
              label="Entidade"
              options={drops?.Entidade}
              onValueChange={onChangeEntidade}
              refresh="Entidade"
            />
          </Grid>
          <Grid
            item
            xs={2}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Detalhes/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <Visibility fontSize="small" />
            </IconButton>
            <IconButton
              color="primary"
              size="small"
              onClick={() =>
                window.open(
                  `https://cadastros.eprom2.com.br/app/Entidades/Indicadores/${watch(
                    'cadastro_id'
                  )}`
                )
              }
              disabled={!Boolean(watch('cadastro_id'))}
            >
              <QueryStats fontSize="small" />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="celocal_id"
              control={control}
              label="Local de Estoque"
              options={drops?.LocalEstoque?.filter(
                (f) =>
                  !Boolean(f?.filial_id) || f?.filial_id === watch('filial_id')
              )}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="moeda_id"
              control={control}
              label="Moeda para Conversão"
              options={drops?.Moeda}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="cotacao"
              control={control}
              label="Cotação da moeda"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input name="contato" control={control} label="Contato" />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="inspecao"
              control={control}
              label="Inspeção"
              options={dropBoolean}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="observacao"
              control={control}
              label="Observação"
              multiline
              rows={5}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Input
              name="dadosadc"
              control={control}
              label="Dados Adicionais"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Card title="Itens" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} textAlign="center">
            <ButtonMenu
              buttonProps={{ variant: 'outlined' }}
              options={options}
            />
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <VirtualDrop
                    name="produto_id"
                    control={control}
                    label="Produto"
                    options={drops?.Produto}
                    onValueChange={(v) =>
                      Boolean(v) && getAdditionals(v?.value)
                    }
                    refresh="Produto"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input name="descricao" control={control} label="Descrição" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="quantidade"
                    control={control}
                    label="Quantidade"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Dropdown
                    name="unidade_medida_id"
                    control={control}
                    label="Unidade de Medida"
                    options={drops?.UnidadeMedida}
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <Input
                    name="referencia"
                    control={control}
                    label="Referência"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="desconto1_pc"
                    control={control}
                    label="% Desconto"
                  />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask name="preco" control={control} label="Preço" />
                </Grid>
                <Grid item xs={12} sm={4}>
                  <InputMask
                    name="przent"
                    control={control}
                    label="Prazo de Entrega"
                    type="date"
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="planoconta_id"
                    control={control}
                    label="Plano de Conta"
                    options={drops?.Planoconta?.filter(
                      (p) => p?.operacional === 'SIM'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="ccusto_id"
                    control={control}
                    label="Centro de Custo"
                    options={drops?.Ccusto}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="aplicacao_id"
                    control={control}
                    label="Aplicação"
                    options={drops?.Aplicacao}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="frota_id"
                    control={control}
                    label="Frota"
                    options={drops?.Frota}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Input
                    name="infadc"
                    control={control}
                    label="Informações Adicionais"
                  />
                </Grid>
                <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                  <Box
                    component="img"
                    sx={styles?.image}
                    src={additionals?.imagem || Blank}
                  />
                </Grid>
                {Boolean(loading) ? (
                  <Grid item xs={12} sm={6} {...styles?.detailsProps}>
                    <Loader />
                  </Grid>
                ) : (
                  <Grid item xs={12} sm={6} {...styles?.textDetailsProps}>
                    <Typography variant="h6" color="primary">
                      {additionals?.descricao}
                    </Typography>
                    <Typography variant="body1">
                      Referência: {additionals?.referencia || '-'}
                    </Typography>
                    <Typography variant="body1">
                      Saldo: {additionals?.saldo || '0'}{' '}
                      {additionals?.UnidadeMedida?.descricao || ''}
                    </Typography>
                    <Typography variant="body1">
                      Localização: {additionals?.localizador || '-'}
                    </Typography>
                    <Typography variant="h6" align="right" color="primary">
                      {subtotal?.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </Typography>
                  </Grid>
                )}
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    loading={loading}
                    onClick={handleSubmit(onAddItem)}
                  >
                    ADICIONAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Referência</TableCell>
                          <TableCell>Descrição</TableCell>
                          <TableCell>Quantidade</TableCell>
                          <TableCell>Desconto</TableCell>
                          <TableCell>Preço Unitário</TableCell>
                          <TableCell>Sub Total</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('itens')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.referencia}</TableCell>
                            <TableCell>{item?.descricao}</TableCell>
                            <TableCell>{item?.quantidade}</TableCell>
                            <TableCell>
                              {item?.desconto_vlr?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.preco?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {item?.subtotal?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <ItemModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditItem({ values, index })
                                      }
                                    />,
                                    'Editar Item'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeleteItem(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('itens'), 'subtotal')?.toLocaleString('pt-br', {
                style: 'currency',
                currency: 'BRL',
              })}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Pagamento" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} display="flex">
            <Card style={styles?.semiCard}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="forma_pagto_id"
                    control={control}
                    label="Forma de Pagamento"
                    options={drops?.FormaDePagamento?.filter(
                      (f) => f?.modulo !== 'VENDAS' && f?.modulo !== 'CHEQUES'
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Dropdown
                    name="condicao_pagamento_id"
                    control={control}
                    label="Condição de Pagamento"
                    options={drops?.CondicaoPagamento?.filter(
                      (c) => c?.modulo !== 'VENDAS'
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Checkbox name="fixar" control={control} label="Fixar Dia" />
                </Grid>
                <Grid item xs={12} textAlign="center">
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onChangePagto)}
                  >
                    CALCULAR
                  </Button>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} sm={6} display="flex">
            <Card>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TableContainer>
                    <Table size="small">
                      <TableHead>
                        <TableRow>
                          <TableCell>Parcela</TableCell>
                          <TableCell>Data</TableCell>
                          <TableCell>Valor</TableCell>
                          <TableCell>Forma de Pagamento</TableCell>
                          <TableCell align="center">Ações</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {watch('pagamento')?.map((item, index) => (
                          <TableRow key={index?.toString()}>
                            <TableCell>{item?.parcela}</TableCell>
                            <TableCell>
                              {Boolean(item?.dtvenc) &&
                                moment(item?.dtvenc).isValid() &&
                                moment(item?.dtvenc).format('DD/MM/YYYY')}
                            </TableCell>
                            <TableCell>
                              {item?.valor?.toLocaleString('pt-br', {
                                style: 'currency',
                                currency: 'BRL',
                              })}
                            </TableCell>
                            <TableCell>
                              {findOnArray(
                                item?.forma_pagto_id,
                                drops?.FormaDePagamento,
                                'label'
                              )}
                            </TableCell>
                            <TableCell align="center">
                              <IconButton
                                size="small"
                                onClick={() =>
                                  openDialog(
                                    <PagamentoModal
                                      item={item}
                                      onSubmit={(values) =>
                                        onEditPagto({ values, index })
                                      }
                                    />,
                                    'Editar Parcela'
                                  )
                                }
                              >
                                <Edit />
                              </IconButton>
                              <IconButton
                                size="small"
                                onClick={() => onDeletePagto(index)}
                              >
                                <Delete />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography variant="h6" color="primary">
              Total:{' '}
              {summarizer(watch('pagamento'), 'valor')?.toLocaleString(
                'pt-br',
                { style: 'currency', currency: 'BRL' }
              )}
            </Typography>
          </Grid>
        </Grid>
      </Card>
      <Card title="Envio / Transporte" style={styles?.card}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <InputMask
              name="fretevlr"
              control={control}
              label="Valor do Frete"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Dropdown
              name="fretepc"
              control={control}
              label="Frete por Conta"
              options={dropFretePorConta}
            />
          </Grid>
          <Grid item xs={12}>
            <Dropdown
              name="entidade_id"
              control={control}
              label="Transportador"
              options={drops?.EntidadeTipo?.filter(
                (t) => t?.tipo === 'TRANSPORTADOR'
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Input
              name="entrega"
              control={control}
              label="Observação de Entrega"
              multiline
              rows={5}
            />
          </Grid>
        </Grid>
      </Card>
      <Box textAlign="center" sx={styles?.button}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmit(onSubmit)}
          loading={postLoading}
        >
          Salvar
        </Button>
      </Box>
    </Container>
  );
};

export default Gerar;
